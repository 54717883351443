import React, { useState, Component } from 'react';
import { set } from 'react-ga';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Input } from 'reactstrap';

const initialState = {
	isAuth: true,
	email: ''
};
class EmailAuthModal extends Component {
	constructor(props) {
		super(props);
		this.state = initialState;
	}

	componentDidMount() {
		console.log('Listening for Install prompt');
		window.addEventListener('beforeinstallprompt', (e) => {
			// For older browsers
			e.preventDefault();
			console.log('Install Prompt fired');
			this.installPrompt = e;
		});
	}

	render() {
		const { isAuth, toggle, isResendEmail, modalToggle, onClick, sendEmail, email, errorMessage } = this.props;
		const isAbbott =
			this.state.email.toLowerCase().includes('@abbott.com') ||
			this.state.email.toLowerCase().includes('@simple.science');
		return (
			<div>
				{!isAuth && (
					<Modal isOpen={isResendEmail} className="EmailAuthModal">
						<ModalHeader>EMAIL CONFIRMATION</ModalHeader>
						<ModalBody>
							<FormGroup>
								<Label for="unmountOnClose" className="modalLabel">
									Please check your email for a link to access this application. If you don’t receive
									an email, try re-sending your confirmation email below.
								</Label>
							</FormGroup>
						</ModalBody>
						<ModalFooter>
							<Button color="primary" onClick={() => this.props.sendEmail(this.state.email)}>
								RE-SEND EMAIL
							</Button>
							<a className="toLandingPage" href="https://abbotticm.com">
								<Button color="primary" className="modalClose">
									BACK
								</Button>
							</a>
						</ModalFooter>
					</Modal>
				)}
				{!isAuth && (
					<Modal isOpen={modalToggle} className="EmailAuthModal">
						<ModalHeader>EMAIL CONFIRMATION</ModalHeader>
						<ModalBody>
							<FormGroup>
								<Label for="unmountOnClose" className="modalLabel">
									Please confirm your Abbott.com email address
								</Label>
								<Input
									type="email"
									placeholder="@abbott.com"
									rows={5}
									onChange={(e) => {
										this.setState({ email: e.target.value });
									}}
								/>
							</FormGroup>
						</ModalBody>
						<ModalFooter>
							<Button
								color="primary"
								onClick={() => this.props.sendEmail(this.state.email)}
								style={{ opacity: isAbbott ? 1 : 0.5 }}
								disabled={!isAbbott}
							>
								SUBMIT
							</Button>
							<Label className="errorMessage">{errorMessage}</Label>
						</ModalFooter>
					</Modal>
				)}
			</div>
		);
	}
}

export default EmailAuthModal;
