import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/App';
import * as serviceWorker from './serviceWorker';
import swConfig from './swConfig';
// store the videos into indexdb

import { pdfjs } from 'react-pdf';
import ReactGA from 'react-ga';
const pdfjsWorker = import('pdfjs-dist/build/pdf.worker.entry');
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
//pdfjs.GlobalWorkerOptions.workerSrc = `https://abbot-assets.s3-us-west-2.amazonaws.com/dev/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
//pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/scripts/pdf.worker.js`;

// our fake endpoint to store data
ReactGA.initialize('UA-165926687-9');
/* if ('serviceWorker' in navigator) {
	navigator.serviceWorker.register('/swa.js');
} */
ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register(swConfig);
