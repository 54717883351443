import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Document, Page } from 'react-pdf';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

const pdfs = require.context('../../assets/pdfs', false);

/* async function share(shareLink, shareTitle) {
	try {
		await navigator.share({ title: shareTitle, url: shareLink });
	} catch (err) {
		console.error('Share failed:', err.message);
		try {
			navigator.clipboard.writeText(shareLink);
			alert('Data was copied successfully');
		} catch (e) {
			alert(`Copy failed: ${e.message}`);
		}
	}
} */

const shareBlacklist = [ 'LAUNCH READINESS CHECKLIST', 'LAUNCH PLAYBOOK', 'WORKFLOW MAP' ];

const filesUrl = 'https://abbott-assets.s3-us-west-2.amazonaws.com/JotDx/pdfs/';
class PdfViewer extends Component {
	state = {
		numPages: 0,
		pageOffset: 0,
		pages: [],
		OFFSET_LIMITS: 10
	};

	onDocumentLoadSuccess = ({ numPages }) => {
		const pages = [];
		for (let p = 0; p < numPages; p++) {
			pages.push(
				<div key={`page${p}`} style={{ paddingTop: '5vh', paddingRight: '5w', paddingLeft: '5w' }}>
					<Page pageNumber={p + 1} debug={true} wrap={false} />
				</div>
			);
		}
		this.setState({ pages: pages, numPages: numPages });
	};

	componentDidMount() {
		const params = new URLSearchParams(this.props.location.search);
		const ol = params.get('ol');
		if (ol) {
			this.setState({ OFFSET_LIMITS: ol });
		}
	}

	render() {
		const { pages, pageOffset, numPages, OFFSET_LIMITS } = this.state;
		const params = new URLSearchParams(this.props.location.search);
		const documentTitle = params.get('title');
		const documentFile = params.get('file');
		let pdfFile = pdfs('./' + documentFile);
		let offsetPages = [];
		const startPage = OFFSET_LIMITS * pageOffset;
		for (let p = startPage; p < startPage + OFFSET_LIMITS && p < numPages; p++) {
			offsetPages.push(pages[p]);
		}
		return (
			<div
				style={{
					position: 'fixed',
					top: 0,
					height: '100vh',
					width: '100%',
					background: '#f5f5f5',
					zIndex: '200',
					maxWidth: 768,
					overflowX: 'hidden',
					zIndex: 200
				}}
			>
				<div
					style={{
						position: 'absolute',
						background: 'white',
						width: '100%',
						height: '7vh',
						zIndex: 100,
						display: 'flex',
						alignItems: 'center',
						boxShadow: '0 0 2px lightgray',
						justifyContent: 'space-between',
						paddingRight: 15,
						paddingLeft: 15
					}}
				>
					<div
						style={{ fontSize: '1.5vh', color: 'gray', display: 'flex', width: '20vw' }}
						onClick={() => this.props.history.goBack()}
					>
						<i
							className="las la-times"
							style={{ fontSize: '2vh', color: '#0098d8', marginRight: 5 }}
						/>CLOSE
					</div>
					<div style={{ textAlign: 'center', fontSize: '1.25vh', maxWidth: '50vw' }}>{documentTitle}</div>
					<div style={{ width: '20vw' }}>
						{!shareBlacklist.includes(documentTitle) && (
							<Button
								style={{
									float: 'right',
									border: '1px solid #0098d8',
									color: '#0098d8',
									borderRadius: '50%',
									minWidth: '4vh',
									minHeight: '4vh',
									maxWidth: '4vh',
									maxHeight: '4vh',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									background: 'white'
								}}
								//onClick={() => share(window.location.href, documentTitle)}
								onClick={() => {
									ReactGA.event({
										category: 'Content Share',
										action: `User shared ${documentTitle}`
									});
									window.open(
										`mailto:?subject=${documentTitle}&body=${filesUrl}${documentFile
											.replace(/ /g, '')
											.replace('-min.pdf', '.pdf')}`
									);
								}}
							>
								<i className="las la-envelope" />
							</Button>
						)}
					</div>
				</div>
				<div className="loadMoreButtonContainer">
					{pageOffset > 0 && (
						<button
							onClick={() => this.setState({ pageOffset: pageOffset - 1 })}
							className="loadMoreButton"
						>
							{' '}
							<i className="las la-angle-left" /> LOAD PAST {OFFSET_LIMITS}
						</button>
					)}
					{pageOffset < Math.floor(numPages / OFFSET_LIMITS) && (
						<button
							onClick={() => this.setState({ pageOffset: pageOffset + 1 })}
							className="loadMoreButton"
						>
							LOAD {OFFSET_LIMITS} MORE <i className="las la-angle-right" />
						</button>
					)}
				</div>
				<div
					style={{
						height: '100%',
						width: '100%',
						overflowX: 'hidden',
						paddingTop: '70px',
						paddingBottom: '5vh'
					}}
				>
					<Document
						file={pdfFile}
						onLoadSuccess={this.onDocumentLoadSuccess}
						onLoadError={(error) => alert('Error while loading document! ' + error.message)}
					>
						{offsetPages.map((page) => page)}
					</Document>
				</div>
			</div>
		);
	}
}

export default withRouter(PdfViewer);
