import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

class GradientIconButton extends Component {
	render() {
		const { text, gradientClass, linkPath, iconClass, onClick, iconImg } = this.props;
		return linkPath ? (
			<Link to={linkPath}>
				<Button className="action-button" style={{ background: 'white' }}>
					<div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
						<div
							className={gradientClass}
							style={{
								display: 'flex',
								alignItems: 'center',
								height: '100%',
								width: '8vh',
								marginRight: 15
							}}
						>
							{iconImg ? (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										textAlign: 'center',
										width: '100%'
									}}
								>
									<img
										src={iconImg}
										style={{
											maxHeight: '5vh',
											margin: 'auto',
											maxWidth: '50%'
										}}
									/>
								</div>
							) : (
								<i className={iconClass} style={{ fontSize: '5vh', margin: 'auto' }} />
							)}
						</div>
						<span
							style={{
								textAlign: 'left',
								lineHeight: '2vh',
								fontSize: '1.5vh',
								color: '#63666A',
								fontFamily: 'BrandonGrotesqueBold',
								marginRight: 20
							}}
						>
							{text}
						</span>
						<i
							className="las la-angle-right"
							style={{ marginLeft: 'auto', marginRight: 5, color: 'gray' }}
						/>
					</div>
				</Button>
			</Link>
		) : (
			<Button className="action-button" style={{ background: 'white', ...this.props.style }} onClick={onClick}>
				<div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
					<div
						className={gradientClass}
						style={{
							display: 'flex',
							alignItems: 'center',
							height: '100%',
							width: '8vh',
							marginRight: 15
						}}
					>
						{iconImg ? (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									textAlign: 'center',
									width: '100%'
								}}
							>
								<img
									src={iconImg}
									style={{
										maxHeight: '5vh',
										margin: 'auto',
										maxWidth: '50%'
									}}
								/>
							</div>
						) : (
							<i className={iconClass} style={{ fontSize: '5vh', margin: 'auto' }} />
						)}
					</div>
					<span
						style={{
							textAlign: 'left',
							lineHeight: '2vh',
							fontSize: '1.5vh',
							color: '#63666A',
							fontFamily: 'BrandonGrotesqueBold'
						}}
					>
						{text}
					</span>
					<i className="las la-angle-right" style={{ marginLeft: 'auto', marginRight: 5, color: 'gray' }} />
				</div>
			</Button>
		);
	}
}

export default GradientIconButton;
