import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

class GradientButton extends Component {
	render() {
		/**
		 * border - the border styles; only will exists if it has a border
		 * 
		 */
		const {
			text,
			gradientClass,
			linkPath,
			iconClass,
			iconImg,
			littleSpaceLeft,
			textColor,
			border,
			noLink,
			togglePopUp
		} = this.props;

		return noLink ? (
			<Button onClick={togglePopUp} style={{ border: border }} className={`action-button ${gradientClass}`}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					{iconImg ? (
						<div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', width: '8vh' }}>
							<img
								src={iconImg}
								style={{ maxHeight: '3vh', maxWidth: 30, width: 'auto', margin: 'auto' }}
							/>
						</div>
					) : littleSpaceLeft ? (
						<i className={iconClass} style={{ width: '5%' }} />
					) : (
						<i className={iconClass} style={{ width: '20%' }} />
					)}
					<span
						style={{
							width: littleSpaceLeft ? '70%' : '55%',
							textAlign: 'left',
							lineHeight: '2.2vh',
							fontFamily: 'BrandonGrotesqueBold',
							fontSize: '1.7vh',
							color: textColor,
							whiteSpace: 'nowrap'
						}}
					>
						{text}
					</span>
					<i
						className="las la-angle-right"
						style={{ float: 'right', position: 'absolute', right: 25, color: textColor }}
					/>
				</div>
			</Button>
		) : (
			<a href={linkPath} target="_blank">
				<Button style={{ border: border }} className={`action-button ${gradientClass}`}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{iconImg ? (
							<div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', width: '8vh' }}>
								<img
									src={iconImg}
									style={{ maxHeight: '3vh', maxWidth: 30, width: 'auto', margin: 'auto' }}
								/>
							</div>
						) : littleSpaceLeft ? (
							<i className={iconClass} style={{ width: '5%' }} />
						) : (
							<i className={iconClass} style={{ width: '20%' }} />
						)}
						<span
							style={{
								width: littleSpaceLeft ? '79%' : '55%',
								textAlign: 'left',
								lineHeight: '2.2vh',
								fontFamily: 'BrandonGrotesqueBold',
								fontSize: '1.7vh',
								color: textColor,
								whiteSpace: 'nowrap'
							}}
						>
							{text}
						</span>
						<i
							className="las la-angle-right"
							style={{ float: 'right', position: 'absolute', right: 25, color: textColor }}
						/>
					</div>
				</Button>
			</a>
		);
	}
}

export default GradientButton;
