export default {
	onUpdate: (registration) => {
		/* 
		registration.unregister().then(() => {
			console.log('Reloading page');
			window.location.reload();
        }); */
		console.log('unregistering service worker');
		const waitingServiceWorker = registration.waiting;

		if (waitingServiceWorker) {
			waitingServiceWorker.addEventListener('statechange', (event) => {
				if (event.target.state === 'activated') {
					window.location.reload();
				}
			});
			waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
		}
	},
	onSuccess: (registration) => {
		console.info('service worker on success state');
		console.log(registration);
	}
};
