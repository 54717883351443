import React, { Component } from 'react';
import axios from 'axios';
import { Router, Switch, Route, Link } from 'react-router-dom';
import NavBar from '../Components/NavBar';
import Dashboard from '../Pages/Dashboard';
import AHPResources from '../Sections/ResourceLibraries/AHPResources';
import PatientResources from '../Sections/ResourceLibraries/PatientResources';
import SalesResources from '../Sections/ResourceLibraries/SalesResources';
import OtherResources from '../Sections/ResourceLibraries/OtherResources';
import PdfViewer from '../Components/PopUp/PdfViewer';
import IndicationSafetyWarnings from '../Sections/ResourceLibraries/IndicationSafetyWarnings';
import EmailAuthModal from '../Components/PopUp/EmailAuthModal';

import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';

const history = createBrowserHistory();
history.listen((location) => {
	if (navigator.serviceWorker) {
		navigator.serviceWorker.getRegistrations().then((registrationsArray) => {
			registrationsArray[0] && registrationsArray[0].update();
		});
	}
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
});

function parseJwt(token) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map(function(c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	return JSON.parse(jsonPayload);
}
function setCookie(cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	let expires = 'expires=' + d.toUTCString();
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}
function getCookie(cname) {
	let name = cname + '=';
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

class router extends Component {
	constructor(props) {
		super(props);

		var appState = {
			showPopUpInstallOnce: false,
			isAuth: false,
			modalToggle: true,
			isResendEmail: false,
			errorMessage: ''
		};
		var urlString = window.location.search;
		var urlParams = new URLSearchParams(urlString);
		var jwt = urlParams.get('jwt');
		var parsedJwt = jwt && parseJwt(jwt);
		const storedEid = getCookie('eid');
		if (parsedJwt && parsedJwt.eid && parsedJwt.eid.toLowerCase().includes('abbott')) {
			setCookie('eid', parsedJwt.eid, 1);
			window.location.replace(window.location.origin);
		} else if (storedEid) {
			appState = { ...appState, isAuth: true, isResendEmail: false, modalToggle: false };
		} else {
			window.open(`https://abbott-sso.s2client.com?ref=${window.location.host}`, '_self');
		}
		this.state = appState;
	}

	sendEmail = (email) => {
		var currentDate = new Date().getTime();

		var token = currentDate;

		axios
			.post('https://auth.abbotticm.com', { toEmail: email, key: token })
			.then((response) => {
				if (response.data.success && !response.data.emailSent) {
					localStorage.setItem('email', email);
					localStorage.setItem('hba', true);
					localStorage.setItem('exp', response.data.exp);
					this.setState({ isAuth: true, isResendEmail: false, modalToggle: false });
				} else if (response.data.emailSent) {
					this.setState({ isAuth: false, isResendEmail: true, modalToggle: false });
				} else {
					this.setState({ isAuth: false, isResendEmail: true, modalToggle: false });
					this.setState({ errorMessage: response.data.error });
					console.log(response.data.error);
				}
				console.log('serverSuccess', response);
			})
			.catch((error) => {
				this.setState({ errorMessage: error });
				console.log(error);
			});
	};

	auth = (email, token) => {
		axios
			.post('https://auth.abbotticm.com/auth.php', { email: email, token: token })
			.then((response) => {
				if (response.data.success) {
					localStorage.setItem('hba', true);
					localStorage.setItem('emailKey', token);
					localStorage.setItem('exp', response.data.exp);
					this.setState({ isAuth: true, isResendEmail: false, modalToggle: false });
				} else {
					this.setState({ isAuth: false, isResendEmail: false, modalToggle: true });
				}
				console.log('serverSuccess', response);
			})
			.catch((error) => {
				this.setState({ errorMessage: error });
				console.log(error);
			});
	};
	componentDidMount() {
		if (localStorage.getItem('promptShown')) {
			this.setState({ showPopUpInstallOnce: true });
		}
	}

	updateIfPopUpAlreadyShown = () => {
		this.setState({ showPopUpInstallOnce: true });
		localStorage.setItem('promptShown', true);
	};

	render() {
		const { isAuth, modalToggle, isResendEmail, errorMessage } = this.state;
		return (
			isAuth && (
				<Router history={history}>
					<NavBar />

					<Switch>
						<Route path="/" exact>
							<Dashboard
								updateIfPopUpAlreadyShown={this.updateIfPopUpAlreadyShown}
								showPopUpInstallOnce={this.state.showPopUpInstallOnce}
							/>
						</Route>

						<Route path="/resource-libraries/patient-resources" exact>
							<PatientResources />
						</Route>
						<Route path="/resource-libraries/hcp-resources" exact>
							<AHPResources />
						</Route>
						<Route path="/resource-libraries/sales-resources" exact>
							<SalesResources />
						</Route>
						<Route path="/resource-libraries/other-resources" exact>
							<OtherResources />
						</Route>

						<Route path="/resource-libraries/pdf-viewer" exact>
							<PdfViewer />
						</Route>
						<Route path="/resource-libraries/indications-safety-warnings" exact>
							<IndicationSafetyWarnings />
						</Route>
					</Switch>
				</Router>
			)
		);
	}
}

export default router;
