import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import logo from '../assets/images/ABTLogo.png';
import { withRouter } from 'react-router-dom';

class NavBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			navMenuOpen: false
		};
	}

	componentDidMount() {
		this.props.history.listen(() => {
			this.setState({ navMenuOpen: false });
		});
	}

	toggleMenu = () => {
		this.setState({ navMenuOpen: !this.state.navMenuOpen });
	};

	render() {
		const { navMenuOpen } = this.state;

		return (
			<div>
				<div className="navbar">
					<Link to="/">
						<img src={logo} style={{ height: '4vh' }} />
					</Link>
				</div>
			</div>
		);
	}
}

export default withRouter(NavBar);
